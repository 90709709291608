<template>
  <div class="box">
    <!--    <div class="header" v-if="show">文章内容</div>-->
    <!--    <div class="header">{{ content }}12345</div>-->
    <div class="article-box">
      <div class="title">{{ details.title }}</div>
      <div class="instructions" v-if="details.content">
        <span class='time'>发布于 {{ details.publishTime | formatDate }}</span>
        <span class='level' v-if="details.tagList.length > 0">{{ details.tagList ? details.tagList[0] : '' }}</span>
      </div>
      <div class="main">
        <video class="video" :poster="details.image" controls v-if="details.relationUrl"
               :src="details.relationUrl"></video>
        <div class="content" v-html="details.content"></div>
      </div>
    </div>
    <div class="bottom">
      <van-button class="btn" icon="https://cdn.cailu88.com/jingxuanshi/dontlike.png" @click="like"
                  v-if="!details.hasLike">
        <span>点赞({{ details.like }})</span>
      </van-button>
      <van-button class="btn active" icon="https://cdn.cailu88.com/jingxuanshi/like.png" v-else>
        <span>已赞({{ details.like }})</span>
      </van-button>
      <van-button class="btn" icon="https://cdn.cailu88.com/jingxuanshi/detailsShare.png" @click="handleShare">
        <span>分享文章</span>
      </van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import baseUrl from '../../utils/baseUrl'
import { Button, Toast } from 'vant'

Vue.use(Button)
Vue.use(Toast)
const api = require('../../utils/api').api
export default {
  name: 'index',
  data () {
    return {
      version: '',
      channel: '',
      details: {},
      show: false,
      plateName: '',
      token: '',
      id: '',
      content: '',
    }
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? ('0' + MM) : MM
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      return y + '-' + MM + '-' + d
      // let h = date.getHours()
      // h = h < 10 ? ('0' + h) : h
      // let m = date.getMinutes()
      // m = m < 10 ? ('0' + m) : m
      // let s = date.getSeconds()
      // s = s < 10 ? ('0' + s) : s
      // return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    }
  },
  mounted () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    // this.content = this.token;
    this.id = this.$route.query.id
    let u = window.navigator.userAgent.includes('jxsapp')
    u ? this.show = false : this.show = true
    this.$axios(api.article, {
      params: {
        id: this.id,
        clientType: 2
      }
    }).then(res => {
      this.details = res.data.data
      if (res.data.data.content.indexOf('</video>') > 0) {
        this.details.content = res.data.data.content.substring(res.data.data.content.indexOf('</video>') + 8)
      }
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    like () {
      this.$axios(api.articleLike, {
        params: {
          id: this.id,
          clientType: 2
        }
      }).then(res => {
        this.details.hasLike = true
        this.details.like = this.details.like + 1
      }).catch(err => {
        console.info(err)
      })
    },
    handleShare () {
      let event = 'share'
      let param = {
        type: 'link',
        content: this.details.title,
        imageUrl: `https://cdn.cailu88.com/jingxuanshi/share_${this.details.plateId == '5' ? 'counsel' : 'school'}@2x.jpg`,
        url: `${baseUrl.pageUrl}/article/details?id=${this.$route.query.id}&token=${this.$route.query.token}`,
      }
      this.$h5AppLink(event, param)
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .content {
  p {
    img {
      width: 100%;
    }
  }
}

.box {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: sticky;
    top: 0;
    left: 0px;
    background: white;
    min-width: 375px;
  }

  .article-box {
    padding: 10px 15px 0 15px;
    box-sizing: border-box;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 20px;
    }

    .instructions {
      margin-top: 5px;
      display: flex;
      align-items: center;

      .time {
        font-size: 12px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 20px;
      }

      .level {
        display: inline-block;
        min-width: 72px;
        padding: 0 5px;
        height: 20px;
        padding: 0 5px;
        background: rgba(255, 244, 141, 0.3);
        border-radius: 10px;
        border: 1px solid rgba(255, 234, 28, 1);
        font-size: 12px;
        color: #999;
        text-align: center;
        line-height: 20px;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .main {
      margin-top: 15px;

      .content {
        min-width: 345px;
        font-size: 15px;
        line-height: 21px;
        padding-bottom: 50px;
      }

      video {
        width: 345px;
        height: 225px;
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #ccc;
    position: fixed;
    bottom: 0;

    img {
      width: 25px;
      height: 25px;
      margin-right: 3px;
    }

    .btn {
      border: none;
      border-radius: 0;
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 13px;
      height: 45px;
      background: #ffffff;
      justify-content: center;
    }

    //.active{
    //  background: #f1f1f1;
    //}
    .btn:first-of-type {
      border-right: 1px solid #ccc;
    }
  }
}
</style>
